//正式
// export const baseUrl="https://gateway.yyj-health.com"
//测试
export const baseUrl = "https://c-gateway.yyj-health.com";
// export const baseUrl="http://sczyzk.f3322.net:3333"

//ca  url正式
// export const caUrl = "https://h5.scca.com.cn:8110/open/api/data/fetchPageUrl";
//ca  url测试
export const caUrl =
  "http://testmicrosrv.scca.com.cn:9647/open/api/data/fetchPageUrl";

// ca正式 appId
// export const appId = "SCCA1608309382454489090";
// ca测试 appId
export const appId = "SCCA1509377072996134914";

//正式 跳转地址
// export const jumpUrl = "https://pha.yyj-health.com";
// 测试  跳转地址
export const jumpUrl = "https://c-pha.yyj-health.com";

// 跳转参数正式
export const code = "5000";
// 跳转参数测试
// export const code="7000"

// export const jumpUrl="http://t-pha.yyj-health.com"
// export const jumpUrl="http://192.168.10.220:4004"

// export const baseUrl0="https://h5.sczyzk.com"
//export const baseUrl="https://d-gateway.sczyzk.com"

// 微信授权登录跳转
export const baseUrl2 = "https://pay.sczyzk.com/pay/weixin/user";
// 问诊跳转
export const wzUrl = "http://wz.sczyzk.com";
// 支付
export const zfUrl = "https://pay.sczyzk.com/pay";
// export const zfUrl="https://gateway.yyj-health.com"
